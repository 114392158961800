
import { Vue, Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";
import { Orcamento } from "@/core/models/compras";
import { OrcamentoService } from "@/core/services/compras/OrcamentoService";

@Component
export default class ListaOrcamento extends mixins(Vue, ListPage) {
  public service = new OrcamentoService();
  public item: Shared.IEntity = new Orcamento();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  dialogDetalhar: boolean = false;
  sheet:boolean = false;
  totalLista: number = 0;

  titulo: string = "Orçamento";
  subTitulo: string = "Lista dos Orçamentos cadastrados no sistema";
  temBuscaRapida: boolean = true;
  icone: string = "mdi-cash";
 
  itemsPerPage = 15;
  options: any = {
    itemsPerPage: 15,
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nº Orçamento", value: "id" },
    { text: "Identificação", value: "descricao" },
    { text: "Situação", value: "situacao.nome" },
    { text: "Aprovado", value: "aprovacao.aprovado" },
  ]; 

  filter = {
    aprovado:false,
    id:0,
    descricao: ''
  }

  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Orçamento', disabled: true, href: '/financeiro/painel/despesas'}
  ]


  @Watch("options", { deep: true })
  Atualizar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers,this.filter,'','', 'Situacao, Aprovacao').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
      .finally(() => (this.loading = false, this.sheet = false, this.filter={aprovado:false, id:0, descricao: ''}));
  }

  
  Novo() {
    this.item = new Orcamento();
    this.dialogCadastro = true;  
  }

  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  }  

  Detalhar(item) {
    this.service.ObterPorId(item.id,'Fornecedores.Fornecedor, Itens.ItemFornecedores, Itens.Produto, Documentos, Classificacao, Situacao, Empresa').then(
      res => {
        this.item = res.data
        this.dialogDetalhar = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
  }

  Editar(item) {
    this.service.ObterPorId(item.id, 'Fornecedores.Fornecedor,Fornecedores.FormaPagamento, Itens.Produto, Itens.ItemFornecedores, Documentos').then(
      res => {
        this.item = res.data 
        this.dialogCadastro = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
  }

  Aprovar(item) {
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja  Aprovar o registro atual?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return this.service.AprovarOrcamento(item.id).then(
        (res) => {
          if (res.status == 200) {
            this.Atualizar();
            return res;
          }
        },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
    },
    //@ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  Cancelar(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarOrcamento(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar();
              return res;
            }
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }); 
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  situacaoColor(id: any) {
    switch (id) {
      case 1:
        return "green lighten-3";
      case 2:
        return "blue lighten-3";
      case 3:
        return "teal lighten-3";
      case 4:
        return "lime lighten-3";
      case 5:
        return "red lighten-3";
      default:
        return "";
    }
  }
}
